import { ToastContainer } from "react-toastify";
import React, { useState, useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";

import { setToken } from "../api/index";
import { AuthContext } from "../context/authProvider";

import Login from "../views/login/index.jsx";
import ThemeRoutes from "../routes/routing.jsx";
import logo from "../assets/images/logo-pink.png";
import Loader from "../components/loader/loader.jsx";
import Header from "./layout-components/header/header.jsx";
import bubbles from "../assets/images/background/dotsBg.png";
import LoginBg from "../assets/images/background/LoginBg.jpg";
import Sidebar from "./layout-components/sidebar/sidebar.jsx";
import ToastContextProvider from "../context/toastProvider.js";
import authService from "../api/services/authService";

const Fulllayout = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { loggedIn } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  props.history.listen((location, action) => {
    if (typeof window !== "undefined") {
      if (
        window.innerWidth < 767 &&
        document
          .getElementById("main-wrapper")
          .className.indexOf("show-sidebar") !== -1
      ) {
        document
          .getElementById("main-wrapper")
          .classList.toggle("show-sidebar");
      }
    }
  });

  const initCheck = async () => {
    if (localStorage.getItem("token") !== "") {
      setToken(localStorage.getItem("token"));
      const verifyToken = await authService.tokenVerify({
        token: localStorage.getItem("token"),
      });
      if (verifyToken.success) {
        setIsLoading(false);
      } else {
        localStorage.removeItem("token");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        const minWidth = 1024;
        const windowWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        setIsDesktop(windowWidth >= minWidth);
      }
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    initCheck();
  }, [loggedIn]);

  useEffect(() => {
    const updateDimensions = () => {
      if (typeof window !== "undefined") {
        let element = document.getElementById("main-wrapper");
        setWidth(window.innerWidth);
        if (width < 1170) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
          element.classList.add("mini-sidebar");
        } else {
          element.setAttribute("data-sidebartype", "full");
          element.classList.remove("mini-sidebar");
        }
      }
      if (document.readyState === "complete") {
        updateDimensions();
      }
      window.addEventListener("resize", updateDimensions.bind(this));
      window.addEventListener("load", updateDimensions.bind(this));
      return () => {
        window.removeEventListener("load", updateDimensions.bind(this));
        window.removeEventListener("resize", updateDimensions.bind(this));
      };
    };
  }, [width]);

  return (
    <>
      {isDesktop ? (
        <div
          id="main-wrapper"
          data-theme="light"
          data-layout="vertical"
          data-sidebartype="full"
          data-sidebar-position="fixed"
          data-header-position="fixed"
          data-boxed-layout="full"
        >
          <ToastContextProvider>
            <Loader isLoading={isLoading} />
            <Header />
            <ToastContainer
              position="top-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {localStorage.getItem("token") ? (
              <>
                <Sidebar {...props} routes={ThemeRoutes} />
                <div className="page-wrapper d-block">
                  <div className="page-content container-fluid">
                    <Switch>
                      {ThemeRoutes.map((prop) => {
                        return (
                          <Route
                          exact
                            path={prop.path}
                            component={prop.component}
                            key={prop.path}
                          />
                        );
                      })}
                    </Switch>
                  </div>
                </div>
              </>
            ) : (
              <div className="page-wrapper d-block ml-0">
                <div className="page-content container-fluid">
                  <Login />
                </div>
              </div>
            )}
          </ToastContextProvider>
          <img src={bubbles} className="bubbles" />
        </div>
      ) : (
        <div
          className="page-content container-fluid"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
            alignContent: "center",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <img
            src={LoginBg}
            style={{ position: "absolute", zIndex: 1, height: "100vh" }}
          />
          <div
            style={{
              zIndex: 2,
              backgroundColor: "#FFFFFFF0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 20,
              justifyItems: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={logo}
              style={{
                height: 120,
                width: 120,
                objectFit: "contain",
                zIndex: 2,
              }}
            />
            <h4
              style={{
                textAlign: "center",
                zIndex: 2,
                padding: 20,
                paddingTop: 0,
              }}
            >
              For the best experience, access your secure dashboard on a tablet,
              laptop or desktop computer.
            </h4>
          </div>
        </div>
      )}
    </>
  );
};
export default Fulllayout;
