import * as api from "..";
import { PARTNERS } from "../config/endpoints";

interface StaffService {
  updateStatus: (body: { partnerID: string; active: 0 | 1 }) => Promise<any>;
  getAllPartners: (params: { limit: number; offset: number; status?: string; }) => Promise<any>;
  deletePartner: (body: { partnerID: string }) => Promise<any>;
  restorePartner: (body: { partnerID: string }) => Promise<any>;
}

const staffService: StaffService = {
  updateStatus: async (body) => {
    const resp = await api.post(PARTNERS.updateStatus, body);
    return resp;
  },
  getAllPartners: async (params) => {
    const resp = await api.get(PARTNERS.getAllPartners, params);
    return resp;
  },
  deletePartner: async (body) => {
    const resp = await api.deleteApi(
      `${PARTNERS.deletePartner}${body.partnerID}`
    );
    return resp;
  },
  restorePartner: async (body) => {
    const resp = await api.post(
      `${PARTNERS.restorePartner}${body.partnerID}`, null
    );
    return resp;
  },
};

export default staffService;
