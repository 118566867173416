import './assets/scss/style.css';
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import indexRoutes from './routes/index.jsx';
import AuthContextProvider from './context/authProvider.js';

ReactDOM.render(
  <BrowserRouter>
    <AuthContextProvider>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return <Route path={prop.path} key={key} component={prop.component} />
        })}
      </Switch>
    </AuthContextProvider>
  </BrowserRouter>
  , document.getElementById('root')); 
