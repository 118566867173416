import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import Switch from "react-switch";
import staffService from "../../api/services/staffService";

const ListItem = ({ user, index, setStaffList, onDeleteClick, showActions = true, onRestoreClick, showRestore = false }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const onStatusChange = async () => {
    const newStatus = user.ACTIVE === 1 ? 0 : 1;
    setStaffList((prev) => {
      let localData = [...prev];
      localData[index].ACTIVE = newStatus;
      return localData;
    });
    await staffService.updateStatus({
      partnerID: user.PARTNERID,
      active: newStatus,
    });
  };

  return (
    <tr
      className={"font-weight-bolder"}
      // style={user.ACTIVE == 1 ? {} : { color: "darkgrey" }}
    >
      <td style={{ display: "flex", alignItems: "center" }}>
        <h6 className="font-weight-medium mb-0 nowrap">{user.PARTNERNAME}</h6>
      </td>
      <td>{user.EMAILADDRESS}</td>
      <td
        id={"Tooltip-" + user.PARTNERID}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: 250,
        }}
      >
        {user.PARTNERDESCRIPTION}
        <UncontrolledTooltip
          placement="bottom"
          isOpen={tooltipOpen}
          target={"Tooltip-" + user.PARTNERID}
          toggle={toggle}
          delay={500}
        >
          {user.PARTNERDESCRIPTION}
        </UncontrolledTooltip>
      </td>
      <td>{`${user.GIVEBACKPERCENT}%`}</td>
      <td>{user.ACTIVE === 1 ? "Active" : "Disabled"}</td>
      {showActions && <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Switch
            onChange={onStatusChange}
            checked={user.ACTIVE === 1}
            onColor={"#7460ee"}
            checkedIcon={false}
            uncheckedIcon={false}
          />
          <i
            onClick={() => onDeleteClick(index)}
            style={{ color: "red", fontSize: 18, marginLeft: 16 }}
            className="fas fa-trash-alt"
          ></i>
        </div>
      </td>}
      {showRestore && <td>
        <div style={{ display: "flex", alignItems: "center" }}>
        <i
            onClick={() => onRestoreClick(index)}
            style={{ color: "red", fontSize: 18, marginLeft: 16 }}
            className="fas fa-undo-alt"
          ></i>
        </div>
      </td>}
    </tr>
  );
};

export default ListItem;
