import DeletedUsers from "../views/deleted-users/index.jsx";
import UserManager from "../views/user-manager/index.jsx";

var ThemeRoutes = [
  {
    path: "/",
    name: "User Manager",
    icon: "fas fa-users",
    component: UserManager,
  },
  {
    path: "/deleted-users",
    name: "Deleted Users",
    icon: "fas fa-users",
    component: DeletedUsers,
  },
];
export default ThemeRoutes;
