import React from "react";
import { Row, Col } from "reactstrap";

import DeletedUserList from "../../components/userManager/DeletedUserList";

export default function DeletedUsers() {
  return (
    <div>
      <Row>
        <Col sm={12}>
          <DeletedUserList />
        </Col>
      </Row>
    </div>
  );
}
